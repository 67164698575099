import styled from '@emotion/styled';
import React, { Component } from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { MdInfo } from 'react-icons/md';
import { keyframes } from '@emotion/react';

import { colors, radius, spacing } from '../../utils/styles';

const ShippingInfoRoot = styled(`div`)`
  background: #f5f5f5;
  border-radius: ${radius.default}px;
  margin: ${spacing.sm}px 0;
  padding: ${spacing.sm}px ${spacing.md}px;
`;

const Intro = styled(`p`)`
  color: ${colors.text};
  cursor: pointer;
  display: block;
  font-size: 0.95rem;
  margin: 0;
  position: relative;
  text-align: left;
`;

const on = keyframes`
  to {
    opacity: 1;
  }
`;

const Details = styled(Intro)`
  animation: ${on} 1s ease forwards;
  cursor: default;
  display: none;
  margin-top: ${spacing.xs}px;
  opacity: 0;
  transition: 0.5s;

  .expanded & {
    display: block;
  }
`;

const InfoIcon = styled(MdInfo)`
  color: ${colors.lilac};
  margin-right: ${spacing['2xs']}px;
  vertical-align: middle;
`;

class ShippingInfo extends Component {
  state = {
    detailsVisible: false,
  };

  toggle = () => {
    this.setState({ detailsVisible: !this.state.detailsVisible });
  };

  render() {
    const { detailsVisible } = this.state;

    return (
      <ShippingInfoRoot className={detailsVisible ? 'expanded' : ''}>
        <Intro role="button" onClick={this.toggle}>
          <InfoIcon />
          <FormattedMessage id="shippingInfo.internationalSummary" />
        </Intro>
        <Details>
          <FormattedMessage
            id="shippingInfo.internationalDetails"
            values={{
              a: (...chunks) => <a href="mailto:shop@lockcha.com">{chunks}</a>,
            }}
          />
        </Details>
      </ShippingInfoRoot>
    );
  }
}

export default ShippingInfo;
