/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-react-intl';

export default injectIntl(({ intl, locale }) => {
  const brandName = intl.formatMessage({ id: 'metadata.brandName' });
  const title = intl.formatMessage({ id: 'metadata.title' });
  const description = intl.formatMessage({ id: 'metadata.description' });

  return (
    <>
      <Helmet defaultTitle={`${brandName} | ${title}`} titleTemplate={`%s | ${brandName}`}>
        <html lang={locale} />

        <link rel="preconnect" href="https://lockcha.myshopify.com" />
        <link rel="preconnect" href="https://images.ctfassets.net" />
        <link rel="stylesheet" href="/cwTeXFangSong.css" />

        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="LockCha.com" />
        <meta property="og:description" content={description} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@lockchatea" />

        <meta property="fb:pages" content="292848265473" />
      </Helmet>
    </>
  );
});
