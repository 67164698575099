import React from 'react';

export const defaultInterfaceContext = {
  cartStatus: 'initial',
  currentProductImages: [],
  featureProductImage: () => {},
  isDesktopViewport: null,
  navStatus: 'initial',
  productImageFeatured: null,
  productImagesBrowserStatus: 'initial',
  setCurrentProductImages: () => {},
  toggleCart: () => {},
  toggleProductImagesBrowser: () => {},
};

export default React.createContext(defaultInterfaceContext);
