import PropTypes from 'prop-types';
/** @jsx jsx */
import { Flex, Image, Text, jsx } from 'theme-ui';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

import Button from './shared/Buttons';

const TeaButton = ({ active, category }) => {
  const color = category.split('-')[0];
  return (
    <Button
      variant={active ? 'primary' : 'inverted'}
      sx={{
        // BEGIN workaround for variant not working in mdx
        bg: active ? 'primary' : 'white',
        borderColor: 'primary',
        color: active ? 'white' : 'primary',
        // END workaround for variant not working in mdx
        fontSize: 1,
        m: 1,
        py: 1,
      }}
      to={`/products/${category}`}
    >
      <Image
        src={`/images/tea/${color}-tea.png`}
        sx={{
          height: 16,
          mr: 1,
          verticalAlign: 'text-top',
          width: 16,
        }}
      />
      <FormattedMessage id={`tea.${color}`} />
    </Button>
  );
};

TeaButton.propTypes = {
  active: PropTypes.bool,
  category: PropTypes.string,
};

const TeaButtonGroup = ({ active }) => (
  <Flex
    sx={{
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      mt: 1,
      mb: 4,
      fontSize: 1,
    }}
  >
    <Text as="span" sx={{ fontSize: [1, 1], mr: 2 }}>
      <FormattedMessage id="productListing.filterByTeaType" />
    </Text>
    {[
      'special---tea-set',
      'greenish-tea',
      'green-tea',
      'white-tea',
      'yellow-tea',
      'red-tea',
      'dark-tea',
      'puer---loose',
      'flower',
    ].map((category) => (
      <TeaButton key={category} category={category} active={category === active} />
    ))}
  </Flex>
);

TeaButtonGroup.propTypes = {
  active: PropTypes.string,
};

export default TeaButtonGroup;
