import Client from 'shopify-buy';
import React from 'react';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
});

export const defaultStoreContext = {
  client,
  isCartOpen: false,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
};

const StoreContext = React.createContext(defaultStoreContext);

export const withStoreContext = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <StoreContext.Consumer>
      {(context) => <Component {...props} storeContext={context} />}
    </StoreContext.Consumer>
  );
};

export default StoreContext;
