const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", PingFang, Geneva, sans-serif',
    heading: 'cwTeXFangSong, "Roboto Slab", Georgia, DFKai-SB, STKaiti, serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 13, 15, 16, 18, 21, 24, 36],
  fontWeights: {
    body: 400,
    heading: 300,
    bold: 700,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.125,
  },
  buttons: {
    primary: {
      bg: 'primary',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderWidth: '1px',
      fontFamily: 'body',
    },
    inverted: {
      bg: 'white',
      color: 'primary',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderWidth: '1px',
      fontFamily: 'body',
    },
  },
  colors: {
    alert: '#fffcc9',
    background: '#fff',
    bg: '#ffe',
    muted: '#f7e1e1',
    notice: '#fff2fa',
    primary: '#b83426',
    secondary: '#2a1239',
    text: '#58595b',
    mutedText: '#4a0101',
  },
  breakpoints: ['400px', '550px', '750px', '1300px'],
  text: {
    default: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: [3, 2],
      my: [1, 2],
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: 6,
      mt: [4, 4],
      mb: [1, 2],
    },
    h1: {
      variant: 'text.heading',
      fontSize: 7,
      mt: [4, 4],
      mb: [2, 3],
    },
    h3: {
      variant: 'text.heading',
      fontSize: 5,
      mt: [2, 4],
      mb: [1, 2],
    },
    h4: {
      variant: 'text.heading',
      fontSize: 4,
      mt: 1,
      mb: 1,
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.h1',
      mt: [4, 4],
      mb: [2, 3],
    },
    h2: {
      variant: 'text.heading',
      mt: [4, 4],
      mb: [2, 2],
    },
    h3: {
      variant: 'text.h3',
      mt: [2, 4],
      mb: [1, 2],
    },
    h4: {
      variant: 'text.h4',
      mt: [2, 4],
    },
    p: {
      variant: 'text.default',
      fontSize: [3, 2],
      mt: [1, 1],
      mb: [3, 3],
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    ul: {
      mt: 0,
    },
  },
};

export const { colors } = theme;
export default theme;
