import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React, { Component, useState } from 'react';
/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { MdClose, MdMenu, MdPlayArrow } from 'react-icons/md';
import { keyframes } from '@emotion/react';

import Button from '../shared/Buttons';
import Link from '../shared/Link';
import Logo from '../Layout/Logo';
import { colors, dimensions } from '../../utils/styles';

const NavRoot = styled(`div`)`
  background: ${colors.lightest};
  bottom: 0;
  position: fixed;
  left: 0px;
  top: -1px;
  transform: translateX(-100%);
  transition: transform 0.75s;
  width: 100%;
  will-change: transform;
  z-index: 1001;

  &.open {
    transform: translateX(0%);
  }

  &.closed {
    transform: translateX(-100%);
  }

  ::after {
    background-color: ${colors.lightest};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }

  &.loading {
    ::after {
      opacity: 0.9;
      pointer-events: all;
    }
  }
`;

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  height: ${dimensions.headerHeight};
  justify-content: flex-start;
`;

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0)
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const NavToggle = styled(Button)`
  display: flex;
  height: ${dimensions.headerHeight};
  justify-content: center;
  left: 100vw;
  padding: 0;
  position: relative;
  top: 0;
  transition: all 0.5s ease;
  width: ${dimensions.headerHeight};

  :focus {
    box-shadow: 0 0 0 1px ${colors.accent} inset;
  }

  .open & {
    background: ${colors.lilac};
    color: ${colors.lightest};
    transform: translateX(-200%);
  }

  svg {
    animation: ${iconEntry} 0.75s ease forwards;
    height: 28px;
    margin: 0;
    width: 28px;
  }
`;

const MobileNavLink = ({ content, className, onClick }) => (
  <Link
    className={className}
    sx={{
      p: 3,
      display: 'block',
      fontSize: 4,
      textDecoration: 'none',
    }}
    onClick={onClick}
    key={content.id}
    href={content.href || '#'}
    aria-label={content.aria}
  >
    <FormattedMessage id={content.id} />
  </Link>
);

MobileNavLink.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
  onClick: PropTypes.func,
};

const MobileNavDropdown = ({ content, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment key={content.id}>
      <Flex sx={{ alignItems: 'center', borderBottom: '1px solid #eee' }}>
        <MobileNavLink
          sx={{ flexGrow: 1 }}
          content={content}
          onClick={content.children.length > 0 ? () => setIsOpen(!isOpen) : onClick}
        />
        {content.children.length > 0 && (
          <MdPlayArrow
            sx={{
              width: 28,
              height: 28,
              mr: 10,
              transition: 'all 0.5s ease',
              transform: isOpen ? 'rotate(90deg)' : null,
            }}
          />
        )}
      </Flex>
      <Box
        sx={{
          transition: 'all 0.5s ease',
          visibility: isOpen ? 'visible' : 'hidden',
          maxHeight: isOpen ? '100vh' : 0,
          opacity: isOpen ? 1 : 0,
        }}
      >
        {content.children.length > 0 &&
          content.children.map((child) => (
            <Flex key={child.id} sx={{ alignItems: 'center', borderBottom: '1px solid #eee' }}>
              <MobileNavLink
                sx={{ ml: 3, flexGrow: 1 }}
                content={child}
                onClick={() => {
                  setIsOpen(!isOpen);
                  onClick();
                }}
              />
            </Flex>
          ))}
      </Box>
    </React.Fragment>
  );
};

MobileNavDropdown.propTypes = {
  content: PropTypes.object,
  onClick: PropTypes.func,
};

class Nav extends Component {
  state = {
    className: 'closed',
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    const componentStatusChanged = prevProps.status !== this.props.status;

    if (componentStatusChanged) {
      this.setState({
        className: this.props.status,
      });
    }
  }

  render() {
    const { navContent, status, toggle } = this.props;
    const { className } = this.state;

    return (
      <NavRoot className={`${className} ${this.state.isLoading ? 'loading' : ''}`}>
        <Heading
          sx={{
            borderBottom: '1px solid #eee',
            display: ['flex', null, null, 'none'],
          }}
        >
          <NavToggle
            aria-label="Menu"
            onClick={toggle}
            sx={{ bg: 'background', color: 'primary', borderColor: 'background' }}
          >
            {status === 'open' ? (
              <MdClose />
            ) : (
              <React.Fragment>
                <MdMenu />
              </React.Fragment>
            )}
          </NavToggle>
          <Logo
            sx={{
              ml: '-4.5rem',
            }}
          />
        </Heading>
        {navContent.map((content) => (
          <MobileNavDropdown key={content.id} content={content} onClick={toggle} />
        ))}
      </NavRoot>
    );
  }
}

Nav.propTypes = {
  navContent: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Nav;
