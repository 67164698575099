import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React, { Component } from 'react';
/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import {
  FormattedMessage,
  IntlContextConsumer,
  changeLocale,
  injectIntl,
  useIntl,
} from 'gatsby-plugin-react-intl';
import { map } from 'lodash/fp';

import Link from '../shared/Link';
import Logo from './Logo';
import { breakpoints, colors, dimensions, spacing } from '../../utils/styles';

const NAV_WIDTH = 120;

const HeaderRoot = styled('header')`
  align-items: flex-start;
  background-color: ${colors.lightest};
  border-bottom: 1px solid ${colors.brandLight};
  box-sizing: border-box;
  display: ${(props) => (props.isCovered ? 'none' : 'flex')};
  height: ${dimensions.headerHeight};
  justify-content: space-between;
  left: 0;
  padding-left: ${spacing.md}px;
  padding-right: 60px;
  position: sticky;
  right: 0;
  top: 0;

  &:hover {
    z-index: 1000;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    &.covered {
      display: none;
    }
  }
`;

const NavLink = ({ className, content, onClick }) => {
  const intl = useIntl();

  return (
    <Link
      sx={{
        display: 'block',
        textAlign: 'center',
        textDecoration: 'none',
      }}
      className={className}
      href={content.href || '#'}
      onClick={onClick}
      aria-label={intl.formatMessage({ id: content.id })}
    >
      <Flex
        sx={{
          height: 60,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FormattedMessage id={content.id} />
      </Flex>
    </Link>
  );
};

NavLink.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
  onClick: PropTypes.func,
};

class Header extends Component {
  state = {
    className: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.isDesktopViewport) {
      const imageBrowserStatusChanged =
        this.props.productImagesBrowserStatus !== prevProps.productImagesBrowserStatus;

      if (imageBrowserStatusChanged) {
        if (this.props.productImagesBrowserStatus === 'open') {
          setTimeout(() => {
            this.setState({
              className: 'covered',
            });
          }, 500);
        } else {
          this.setState({
            className: '',
          });
        }
      }
    }
  }

  render() {
    const { className } = this.state;
    const { isDesktopViewport, navContent, intl } = this.props;

    return (
      <React.Fragment>
        <HeaderRoot className={className}>
          <Link href="/" aria-label={intl.formatMessage({ id: 'homepage' })} sx={{ mr: 3 }}>
            <Logo
              sx={{
                mt: '1rem',
                ml: ['5rem', null, null, 0],
                transition: 'margin 0.5s ease-in-out',
              }}
            />
          </Link>
          {isDesktopViewport &&
            map(
              (content) => (
                <React.Fragment key={content.id}>
                  <Box>
                    <NavLink
                      sx={{
                        '~ div': {
                          transition: 'all 0.5s ease',
                          visibility: 'hidden',
                          opacity: 0,
                        },
                        '&:hover ~ div': {
                          visibility: 'visible',
                          opacity: 1,
                        },
                        width: NAV_WIDTH,
                      }}
                      content={content}
                    />
                    {content.children && (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          bg: 'background',
                          borderRadius: '0 0 3px 3px',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          mt: '-1px',
                          width: NAV_WIDTH,
                          '&:hover': {
                            visibility: 'visible',
                            opacity: 1,
                          },
                        }}
                      >
                        {map(
                          (child) => (
                            <NavLink sx={{ width: NAV_WIDTH }} key={child.id} content={child} />
                          ),
                          content.children
                        )}
                      </Flex>
                    )}
                  </Box>
                </React.Fragment>
              ),
              navContent
            )}
          <div sx={{ flexGrow: 1 }}></div>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages
                .filter((l) => l !== currentLocale)
                .map((language) => (
                  <a
                    sx={{
                      alignItems: 'center',
                      cursor: 'pointer',
                      display: 'flex',
                      height: 60,
                      justifyContent: 'center',
                      textAlign: 'center',
                      textDecoration: 'none',
                    }}
                    key={language}
                    onClick={() => changeLocale(language)}
                  >
                    <FormattedMessage id={language} />
                  </a>
                ))
            }
          </IntlContextConsumer>
        </HeaderRoot>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object,
  isDesktopViewport: PropTypes.bool,
  navContent: PropTypes.array.isRequired,
  productImagesBrowserStatus: PropTypes.string.isRequired,
};

export default injectIntl(Header);
