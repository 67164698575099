import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

import { colors, radius } from '../../utils/styles';

const CartThumbnailRoot = styled(GatsbyImage)`
  border: 1px solid ${colors.brandLight};
  border-radius: ${radius.default}px;
  height: 36px;
  width: 36px;
`;

// eslint-disable-next-line react/prop-types, react/display-name
const CartThumbnail = ({ shopifyImages, id: imageId, fallback, ...imageProps }) => {
  let image;

  try {
    // eslint-disable-next-line react/prop-types
    image = shopifyImages.find(({ shopifyId }) => shopifyId === atob(imageId));
  } catch (error) {
    // eslint-disable-next-line react/prop-types
    image = shopifyImages.find(({ shopifyId }) => shopifyId === imageId);
  }

  if (image && image.gatsbyImageData) {
    imageProps.image = image.gatsbyImageData;
  } else {
    imageProps.src = fallback;
  }

  return <CartThumbnailRoot {...imageProps} />;
};

// eslint-disable-next-line react/prop-types, react/display-name
export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allShopifyProduct {
          edges {
            node {
              images {
                shopifyId
                gatsbyImageData(width: 34, height: 34)
              }
            }
          }
        }
      }
    `}
    render={({ allShopifyProduct: { edges } }) => {
      const images = edges.map(({ node }) => node.images).reduce((acc, val) => acc.concat(val), []);

      return <CartThumbnail shopifyImages={images} {...props} />;
    }}
  />
);
