import { css, keyframes } from '@emotion/react';

/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 *    // use the brand color at 25% opacity
 *    border-color: ${colors.brand}40;
 */
export const colors = {
  brandDarker: '#221133',
  brandDark: '#442266',
  brand: '#B83426',
  brandBright: '#e0d6eb',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  lightest: '#ffffff',
  darkest: '#4d4058',
  text: '#333333',
  textMild: '#555555',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  lilac: `#B83426`,
  accent: `#ffb238`,
  error: `#ec1818`,
  lemon: `#ffdf37`,
};

export const spacing = {
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 48,
};

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  desktop: 750,
  hd: 1300,
};

export const radius = {
  default: 2,
  large: 4,
};

export const dimensions = {
  headerHeight: '60px',
  cartWidthDesktop: '400px',
  pictureBrowserAction: {
    widthDesktop: '200px',
    heightMobile: '80px',
  },
};

export const scrollbarStyles = {
  WebkitOverflowScrolling: `touch`,
  '&::-webkit-scrollbar': { width: `6px`, height: `6px` },
  '&::-webkit-scrollbar-thumb': { background: colors.brandBright },
  '&::-webkit-scrollbar-thumb:hover': { background: colors.lilac },
  '&::-webkit-scrollbar-track': { background: colors.brandLight },
};

const simpleEntry = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const deadSimpleEntry = keyframes`
  from {
    opacity: .25;
  }
`;

const swirlEntry = keyframes`
  from {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
`;

export const animations = {
  simpleEntry: css`
    ${simpleEntry} .75s ease forwards
  `,
  deadSimpleEntry: css`
    ${deadSimpleEntry} .5s ease forwards
  `,
  swirlEntry: css`
    ${swirlEntry} 0.5s ease-out both;
  `,
};
