import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Button } from 'theme-ui';
import { Link } from 'gatsby-plugin-react-intl';

import { colors } from '../../utils/styles';

const ButtonBase = styled(Button)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  transition: 0.5s;

  :focus {
    box-shadow: 0 0 0 3px ${colors.accent};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }

  svg {
    height: 1.1em;
    margin-left: ${(props) => (props.iconOnLeft ? 0 : '0.5em')};
    margin-right: ${(props) => (props.iconOnLeft ? '0.5em' : 0)};
    width: 1.1em;
    vertical-align: text-bottom;
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 1px ${colors.accent};
    }
  }
`;

const ButtonAsExternalLink = styled(ButtonBase.withComponent(`a`))`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${colors.primary};
  display: inline-flex;
  text-decoration: none;
`;

const ButtonAsInternalLink = ButtonAsExternalLink.withComponent(
  ({ iconOnLeft, inverse, ...rest }) => <Link {...rest} />
);

class StyledButton extends Component {
  render() {
    const { children, to, href, ref, inverse = false, ...rest } = this.props;

    // automtic recognition of icon placement, works properly only for [text + <Icon>] childrens
    const iconOnLeft = typeof children[0] !== 'string';

    if (to) {
      return (
        <ButtonAsInternalLink to={to} iconOnLeft={iconOnLeft} inverse={inverse} {...rest}>
          {children}
        </ButtonAsInternalLink>
      );
    } else if (href) {
      return (
        <ButtonAsExternalLink href={href} inverse={inverse} iconOnLeft={iconOnLeft} {...rest}>
          {children}
        </ButtonAsExternalLink>
      );
    } else {
      return (
        <ButtonBase inverse={inverse} iconOnLeft={iconOnLeft} {...rest}>
          {children}
        </ButtonBase>
      );
    }
  }
}

StyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  inverse: PropTypes.bool,
  ref: PropTypes.node,
  to: PropTypes.string,
};

export default StyledButton;
