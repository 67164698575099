import PropTypes from 'prop-types';
/** @jsx jsx */
import React from 'react';
import { Box, Grid, Text, jsx } from 'theme-ui';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

import Link from '../shared/Link';
import Logo from './Logo';

// eslint-disable-next-line react/prop-types
const Column = ({ className, children }) => (
  <Box className={className} sx={{ p: 2 }}>
    {children}
  </Box>
);

const FooterLink = ({ className, content }) => (
  <Link
    className={className}
    sx={{
      color: 'bg',
      display: 'block',
      textDecoration: 'none',
      transition: 'all 0.5s ease',
      ':hover': { color: 'primary', textDecoration: 'underline' },
    }}
    href={content.href}
  >
    <FormattedMessage id={content.id} />
  </Link>
);

FooterLink.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
};

// eslint-disable-next-line react/prop-types
const FooterHeader = ({ children }) => (
  <Text sx={{ fontWeight: 'bold', fontSize: [1, 1], m: [0, 0] }}>{children}</Text>
);

const FooterRenderer = ({ content }) =>
  content.map((content) =>
    content.children ? (
      <React.Fragment key={content.id}>
        <FooterHeader>
          {content.href ? <FooterLink content={content} /> : <FormattedMessage id={content.id} />}
        </FooterHeader>
        {content.children.map((child) => (
          <FooterLink key={child.id} content={child} />
        ))}
        <br />
      </React.Fragment>
    ) : (
      <FooterHeader key={content.id}>
        <FooterLink content={content} />
      </FooterHeader>
    )
  );

const Footer = ({ content }) => (
  <footer
    sx={{
      p: [1, 2],
      pb: 3,
      bg: 'text',
      color: 'background',
      fontSize: [1, 1],
    }}
  >
    <Grid columns={[1, 2, 4]} gap={2}>
      <Column>
        <Logo fill="#fff" />
      </Column>
      <Column>
        <FooterRenderer content={[content[0]]} />
      </Column>
      <Column>
        <FooterRenderer content={content.slice(1, 3)} />
      </Column>
      <Column>
        <FooterRenderer content={content.slice(3, 5)} />
        <Text sx={{ fontSize: [0, 0], m: [0, 0], mt: [1, 2] }}>
          <FormattedMessage id="footer.secure" />
        </Text>
        <br />
        <Link href="https://www.shopify.com/security/pci-compliant?utm_medium=shop&utm_source=secure">
          <img
            width="102"
            height="44"
            src="https://cdn.shopify.com/s/images/badges/shopify-secure-badge-dark.svg"
            alt="Shopify secure badge"
          />
        </Link>
        <br />
        <Text sx={{ fontSize: [0, 0], m: [0, 0], mt: [1, 2] }}>
          <FormattedMessage id="footer.copyright" />
          {` ©2003-${new Date().getFullYear()} Lock Cha Tea Shop Ltd.`}
        </Text>
      </Column>
    </Grid>
  </footer>
);

Footer.propTypes = {
  content: PropTypes.array.isRequired,
};

export default Footer;
