import PropTypes from 'prop-types';
import React from 'react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-intl';

const Link = ({ children, className, href, onClick }) => {
  if (href.startsWith('/')) {
    return (
      <GatsbyLink className={className} to={href} onClick={onClick}>
        {children}
      </GatsbyLink>
    );
  }
  const onPage = href.startsWith('#');
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={className}
      href={href}
      onClick={onClick}
      target={onPage ? null : '_blank'}
      rel={onPage ? null : 'noopener noreferrer'}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Link;
