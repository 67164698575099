import React from 'react';
import loadable from '@loadable/component';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { node, object } from 'prop-types';

import Cart from '../Cart';
import Footer from './Footer';
import Header from './Header';
import Nav from '../Nav';
import PageContent from './PageContent';
import SiteMetadata from '../shared/SiteMetadata';
import fbTrack from '../shared/FbTrack';
import InterfaceContext, { defaultInterfaceContext } from '../../context/InterfaceContext';
import StoreContext, { defaultStoreContext } from '../../context/StoreContext';
import { breakpoints } from '../../utils/styles';

const ProductImagesBrowser = loadable(() => import('../ProductPage/ProductImagesBrowser'));

const GlobalStyle = css`
  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    color: #b83426;
  }

  body {
    font-size: 1.6rem;
  }
`;

const Viewport = styled(`div`)`
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

class Layout extends React.Component {
  desktopMediaQuery;

  static propTypes = {
    children: node,
    intl: object,
    location: object,
  };

  state = {
    interface: {
      ...defaultInterfaceContext,
      toggleCart: () => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            cartStatus: this.state.interface.cartStatus === 'open' ? 'closed' : 'open',
          },
        }));
      },
      toggleNav: () => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            navStatus: this.state.interface.navStatus === 'open' ? 'closed' : 'open',
          },
        }));
      },
      toggleProductImagesBrowser: (img) => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            productImagesBrowserStatus: img ? 'open' : 'closed',
            productImageFeatured: img || state.interface.productImageFeatured,
          },
        }));
      },
      featureProductImage: (img) => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            productImageFeatured: img,
          },
        }));
      },
      setCurrentProductImages: (images) => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            currentProductImages: images,
            productImageFeatured: null,
          },
        }));
      },
    },
    store: {
      ...defaultStoreContext,
      addVariantToCart: (variantId, quantity) => {
        if (variantId === '' || !quantity) {
          console.error('Both a size and quantity are required.');
          return;
        }

        this.setState((state) => ({
          store: {
            ...state.store,
            adding: true,
          },
        }));

        const { checkout, client } = this.state.store;
        const checkoutId = checkout.id;
        const lineItemsToUpdate = [
          { variantId: btoa(variantId), quantity: parseInt(quantity, 10) },
        ];

        return client.checkout.addLineItems(checkoutId, lineItemsToUpdate).then((checkout) => {
          const added = checkout.lineItems.slice(-1)[0];

          fbTrack('track', 'AddToCart', {
            content_ids: [added.id],
            content_name: added.title,
            content_type: 'product',
            currency: added.variant.price.currencyCode,
            value: parseInt(added.variant.price.amount, 10),
          });

          this.setState((state) => ({
            store: {
              ...state.store,
              checkout,
              adding: false,
            },
          }));
        });
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res) => {
          this.setState((state) => ({
            store: {
              ...state.store,
              checkout: res,
            },
          }));
        });
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

        return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((res) => {
          this.setState((state) => ({
            store: {
              ...state.store,
              checkout: res,
            },
          }));
        });
      },
    },
  };

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined';
    const existingCheckoutID = isBrowser ? localStorage.getItem('shopify_checkout_id') : null;

    const setCheckoutInState = (checkout) => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id);
      }

      this.setState((state) => ({
        store: {
          ...state.store,
          checkout,
        },
      }));
    };

    const createNewCheckout = () => this.state.store.client.checkout.create();
    const fetchCheckout = (id) => this.state.store.client.checkout.fetch(id);

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID);

        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout);
          return;
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null);
      }
    }

    const newCheckout = await createNewCheckout();
    setCheckoutInState(newCheckout);
  }

  componentDidMount() {
    // Observe viewport switching from mobile to desktop and vice versa
    const mediaQueryToMatch = `(min-width: ${breakpoints.desktop}px)`;

    this.desktopMediaQuery = window.matchMedia(mediaQueryToMatch);
    this.desktopMediaQuery.addListener(this.updateViewPortState);

    this.updateViewPortState();

    // Make sure we have a Shopify checkout created for cart management.
    this.initializeCheckout();
  }

  componentDidUpdate(prevProps) {
    // Close product modal window after navigating "back"
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      prevProps.location.pathname.startsWith('/product/')
    ) {
      this.setState((state) => ({
        interface: {
          ...state.interface,
          productImagesBrowserStatus: 'closed',
        },
      }));
    }
  }

  componentWillUnmount = () => {
    this.desktopMediaQuery.removeListener(this.updateViewPortState);
  };

  updateViewPortState = (e) => {
    this.setState((state) => ({
      interface: {
        ...state.interface,
        isDesktopViewport: this.desktopMediaQuery.matches,
      },
    }));
  };

  render() {
    const { children, intl, location } = this.props;

    const footerContent = [
      {
        id: 'nav.products',
        children: [
          {
            id: 'nav.products.tea',
            href: '/products/special---tea-set/',
          },
          {
            id: 'nav.products.coldBrewTea',
            href: '/products/cold-brew-tea/',
          },
          {
            id: 'nav.products.beer',
            href: '/products/beer/',
          },
        ],
      },
      {
        id: 'nav.locations',
        href: '/locations/',
        children: [
          {
            id: 'nav.locations.hongKongPark',
            href: '/locations/hong-kong-park/',
            footerOnly: true,
          },
          {
            id: 'nav.locations.taiKwun',
            href: '/locations/tai-kwun/',
            footerOnly: true,
          },
        ],
      },
      {
        id: 'nav.about',
        children: [
          {
            id: 'nav.about.lockcha',
            href: '/about/',
          },
          {
            id: 'nav.about.ourTea',
            href: '/about/our-tea/',
          },
          {
            id: 'nav.about.founder',
            href: '/about/founder/',
          },
        ],
      },
      {
        id: 'nav.social',
        footerOnly: true,
        children: [
          {
            id: 'nav.social.facebook',
            href: 'https://facebook.com/lockchahk',
            footerOnly: true,
          },
          {
            id: 'nav.social.instagram',
            href: 'https://instagram.com/lockchahk',
            footerOnly: true,
          },
        ],
      },
      {
        id: 'nav.legal',
        footerOnly: true,
        children: [
          {
            id: 'nav.legal.shipping',
            href: '/legal/shipping/',
            footerOnly: true,
          },
          {
            id: 'nav.legal.privacy',
            href: '/legal/privacy/',
            footerOnly: true,
          },
          {
            id: 'nav.legal.terms',
            href: '/legal/terms/',
            footerOnly: true,
          },
        ],
      },
    ];

    const navContentOnly = (n) => typeof n.footerOnly === 'undefined';

    const navContent = footerContent
      .map((node) => ({
        ...node,
        children: node.children.filter(navContentOnly),
      }))
      .filter(navContentOnly);

    return (
      <>
        <Global styles={GlobalStyle} />
        <SiteMetadata locale={intl.locale} />
        <StoreContext.Provider value={this.state.store}>
          <InterfaceContext.Provider value={this.state.interface}>
            <InterfaceContext.Consumer>
              {({
                isDesktopViewport,
                cartStatus,
                navStatus,
                toggleCart,
                toggleNav,
                productImagesBrowserStatus,
                currentProductImages,
                featureProductImage,
                productImageFeatured,
                toggleProductImagesBrowser,
              }) => (
                <>
                  <Nav navContent={navContent} status={navStatus} toggle={toggleNav} />
                  <Header
                    isDesktopViewport={isDesktopViewport}
                    navContent={navContent}
                    productImagesBrowserStatus={productImagesBrowserStatus}
                  />
                  <Viewport>
                    <Cart
                      isDesktopViewport={isDesktopViewport}
                      status={cartStatus}
                      toggle={toggleCart}
                      productImagesBrowserStatus={productImagesBrowserStatus}
                    />

                    <PageContent
                      cartStatus={cartStatus}
                      isDesktopViewport={isDesktopViewport}
                      location={location}
                      navStatus={navStatus}
                      productImagesBrowserStatus={productImagesBrowserStatus}
                    >
                      <>
                        {children}
                        <Footer content={footerContent} />
                      </>
                    </PageContent>

                    {currentProductImages.length > 0 && (
                      <ProductImagesBrowser
                        featureProductImage={featureProductImage}
                        images={currentProductImages}
                        position={productImagesBrowserStatus}
                        imageFeatured={productImageFeatured}
                        toggle={toggleProductImagesBrowser}
                        isDesktopViewport={isDesktopViewport}
                      />
                    )}
                  </Viewport>
                </>
              )}
            </InterfaceContext.Consumer>
          </InterfaceContext.Provider>
        </StoreContext.Provider>
      </>
    );
  }
}

export default injectIntl(Layout);
