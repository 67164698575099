import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

import CartListItem from './CartListItem';
import { colors, spacing } from '../../utils/styles';

const CartListRoot = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Headers = styled(`div`)`
  border-bottom: 1px solid ${colors.brandBright};
  display: flex;
  justify-content: space-between;

  span {
    color: ${colors.textLight};
    flex-basis: 60px;
    flex-grow: 0;
    padding-bottom: ${spacing.xs}px;
    text-align: center;

    &:first-of-type {
      flex-grow: 1;
      text-align: left;
    }
  }
`;

const CartList = ({ items, handleRemove, updateQuantity, setCartLoading, isCartLoading }) => (
  <>
    <Headers>
      <span>
        <FormattedMessage id="cartList.product" />
      </span>
      <span>
        <FormattedMessage id="cartList.qty" />
      </span>
      <span>
        <FormattedMessage id="cartList.remove" />
      </span>
    </Headers>
    <CartListRoot>
      {items.map((item) => (
        <CartListItem
          key={item.id}
          item={item}
          handleRemove={handleRemove(item.id)}
          updateQuantity={updateQuantity(item.id)}
          setCartLoading={setCartLoading}
          isCartLoading={isCartLoading}
        />
      ))}
    </CartListRoot>
  </>
);

CartList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  handleRemove: PropTypes.func,
  updateQuantity: PropTypes.func,
  setCartLoading: PropTypes.func,
  isCartLoading: PropTypes.bool,
};

export default CartList;
